.ordersTable--main {
  font-size: 1em;
  margin-right: 10px;
}
.ordersTable--main-heading {
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 0.5em;
}

.placeholderText {
  height: 100%;
  width: 100%;
  margin-top: 5%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

.spinner-wrapper {
  height: 100%;
  width: 100%;
  margin-top: 5%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

.menu-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0px 20px;
}

.ant-upload {
  height: 100%;
}
